import { combineReducers } from 'redux'

import photoReducer from './photoSlice'
// import { authReducer } from './authReducer';

// Admin
import { adminReducer } from './admin/adminReducer'
// import { usersReducer } from './admin/usersReducer';
// import { categoriesReducer } from './admin/categoriesReducer';

export const rootReducer = combineReducers({
  photos: photoReducer,
  admin: adminReducer,
  // auth: authReducer,
  // users: usersReducer,
  // categories: categoriesReducer,
})
