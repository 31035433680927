import React from 'react'
import { Provider } from 'react-redux'

import { store } from './store/store'
import AppRouter from './routers/AppRouter'
import { BrowserRouter } from 'react-router-dom';

const RoomGiftsApp = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <AppRouter />
            </BrowserRouter>
        </Provider>
    )
}

export default RoomGiftsApp;
