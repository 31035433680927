import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'

import CssBaseline from '@mui/material/CssBaseline'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import Carousel from 'react-material-ui-carousel'
import './styles.css'

import { useForm, Controller } from 'react-hook-form'
import { Form } from '../shared/Form'
import { Input } from '../shared/Input'
import { yupResolver } from '@hookform/resolvers/yup'
import searchCodeSchema from '../../validators/searchCodeSchema'

import { fetchPhotosByCode } from '../../reducers/photoSlice'

const Copyright = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://pezgato.com/">
        Pezgato
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
const CarouselLanding = (props) => {
  const items = [
    {
      name: 'Jazz And Wine Tour',
      description: 'Sunset Cruise Aboard the Tropicat',
      img: `${process.env.REACT_APP_BASE_IMG_URL}/Pez-Gato-Sunset-Jazz-Wine-Cruise.jpg`,
    },
    {
      name: 'Whale Watching Tour',
      description: `Cabo's World-Famous Tour`,
      img: `${process.env.REACT_APP_BASE_IMG_URL}/Pez-Gato-Whate-Watching.jpg`,
    },
    {
      name: 'Pez Gato Snorkel Tour',
      description: `Swim in beautiful Tropical Waters`,
      img: `${process.env.REACT_APP_BASE_IMG_URL}/Pez-Gato-Snorkel-Adventure.jpg`,
    },
    {
      name: 'Cabo Party Cruise',
      description: `The Original & Unique Booze Tour`,
      img: `${process.env.REACT_APP_BASE_IMG_URL}/Pez-Gato-Sunset-Party-Cruise-Cabo-Boat.jpg`,
    },
    {
      name: 'Mexican Flavors Sunset Tour',
      description: `Explore Mexico by its Flavors`,
      img: `${process.env.REACT_APP_BASE_IMG_URL}/Pez-Gato-CaboMar-Sunset-Dinner-Cruise-Cabo-Boat.jpg`,
    },
  ]

  return (
    <Carousel
      indicators={false}
      navButtonsAlwaysInvisible={true}
      stopAutoPlayOnHover={false}
      swipe={false}
      duration={900}
      interval={6000}
      className="custom-carousel"
    >
      {items.map((item, i) => (
        <CarouselItem key={i} item={item} />
      ))}
    </Carousel>
  )
}
const CarouselItem = (props) => {
  return (
    <Paper
      sx={{
        position: 'relative',
        backgroundColor: 'grey.800',
        color: '#fff',
        height: '100vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${props.item.img})`,
      }}
    >
      {
        <img
          style={{ display: 'none' }}
          src={props.item.img}
          alt={props.item.name}
        />
      }
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'rgba(0,0,0,.4)',
        }}
      />
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              position: 'relative',
              p: { xs: 3, md: 6 },
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              component="h1"
              variant="h3"
              color="inherit"
              gutterBottom
            >
              {props.item.name}
            </Typography>
            <Typography variant="h5" color="inherit" paragraph>
              {props.item.description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

const Img = styled('img')({
  margin: 40,
  display: 'block',
  maxWidth: 160,
  maxHeight: '100%',
})
const theme = createTheme()

const HomeScreen = () => {
  const dispatch = useDispatch()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(searchCodeSchema),
  })

  const status = useSelector((state) => state.photos.status)
  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const onSubmit = async (data, e) => {
    e.preventDefault()
    if (addRequestStatus === 'idle') {
      try {
        data.code = data.code.replaceAll(/\s/g,''); // elimina todos los espacios en blanco
        setAddRequestStatus('pending')
        const resultAction = await dispatch(fetchPhotosByCode(data))
        const photoCode = unwrapResult(resultAction)

        if (photoCode) {
          checkPhotoCode(photoCode)
        } else {
          Swal.fire({
            icon: 'error',
            title: `Download code not found...`,
            text: 'Please check your download code!',
          })
        }
      } catch (err) {
        Swal.fire('Error', err, 'error')
      } finally {
        setAddRequestStatus('idle')
      }
    }
  }

  const checkPhotoCode = (photoCode) => {
    if (photoCode.active) {
      Swal.fire({
        icon: 'success',
        title: 'Download code found',
        text: 'You will be redirect to your photos!',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      }).then(() => {
        window.location.href = photoCode.link
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: `Download code not found...`,
        text: 'Please check your download code!',
      })
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={false} sm={false} md={7}>
          <CarouselLanding />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            <Img
              alt="logo"
              src={`${process.env.REACT_APP_BASE_IMG_URL}/pezgato-logo.png`}
            />
            <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Grid item xs={12}>
                <Controller
                  name="code"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      required
                      label="Download code"
                      error={!!errors.code}
                      helperText={errors.code && errors.code.message}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  mb: (theme) => theme.spacing(2),
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <LoadingButton
                  type="submit"
                  loading={status === 'loading'}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Validate
                </LoadingButton>
              </Grid>
            </Form>
            <Copyright
              sx={{
                my: (theme) => theme.spacing(6),
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default HomeScreen
