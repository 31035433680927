import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import { useForm, Controller } from 'react-hook-form'
import { Form } from '../../shared/Form'
import { Input } from '../../shared/Input'
import { yupResolver } from '@hookform/resolvers/yup'
import editUserSchema from '../../../validators/editUserSchema'

import { getRoles, updateUser } from '../../../reducers/admin/usersSlice'

const EditUser = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(editUserSchema),
  })

  const { activeUser, roles } = useSelector((state) => state.admin.users)
  const usersStatus = useSelector((state) => state.admin.users.status)
  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const [role, setRole] = useState('')

  const handleChange = (event) => {
    setRole(event.target.value)
  }

  useEffect(() => {
    if (addRequestStatus === 'idle') {
      try {
        setAddRequestStatus('pending')
        dispatch(getRoles())
      } catch (err) {
        // console.log(err)
      } finally {
        setAddRequestStatus('idle')
      }
    }
    if (activeUser) {
      reset({
        name: activeUser.name,
        last_name: activeUser.last_name,
        email: activeUser.email,
        username: activeUser.username,
        phone: activeUser.phone,
        active: activeUser.active,
      })
      setRole(activeUser.role)
    }
  }, [activeUser, reset, addRequestStatus, dispatch])

  const onSubmit = async (data, e) => {
    e.preventDefault()
    const formData = { ...data, role }

    if (addRequestStatus === 'idle') {
      try {
        setAddRequestStatus('pending')
        const resultAction = await dispatch(updateUser(formData))
        const user = unwrapResult(resultAction)
        Swal.fire({
          icon: 'success',
          title: `User: ${user.username} has been updated.`,
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          history.push(`/admin/users`)
        })
      } catch (err) {
        Swal.fire('Error', err, 'error')
      } finally {
        setAddRequestStatus('idle')
      }
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: (theme) => theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              mb: (theme) => theme.spacing(2),
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
              }}
            >
              Edit User
            </Typography>
            <Typography variant="body2">
              Date: {moment().locale('en').format('MMM DD, YYYY')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            lg={6}
            sx={{
              mt: (theme) => theme.spacing(2),
            }}
          >
            <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      required
                      label="First Name"
                      error={!!errors.name}
                      helperText={errors.name && errors.name.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="last_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      required
                      label="Last Name"
                      error={!!errors.last_name}
                      helperText={errors.last_name && errors.last_name.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  type="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      required
                      label="Email"
                      error={!!errors.email}
                      helperText={errors.email && errors.email.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="username"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      required
                      label="Username"
                      error={!!errors.username}
                      helperText={errors.username && errors.username.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="phone"
                  type="number"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      required
                      label="Phone"
                      error={!!errors.phone}
                      helperText={errors.phone && errors.phone.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl required fullWidth>
                  <InputLabel id="role_label">Role</InputLabel>
                  <Select
                    labelId="role_label"
                    id="select_role"
                    value={role}
                    label="Role *"
                    onChange={handleChange}
                  >
                    {roles.map((option, index) => (
                      <MenuItem key={index} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* <Select
                    labelId="role_label"
                    id="select_role"
                    value={roleState}
                    label="Role *"
                    onChange={handleChange}
                  >
                    {roles.map((option, index) => (
                      <MenuItem key={index} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select> */}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="active"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Switch
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  )}
                />
                <label>Active</label>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  my: (theme) => theme.spacing(2),
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <LoadingButton
                  type="submit"
                  loading={usersStatus === 'loading'}
                  loadingPosition="start"
                  variant="contained"
                  size="small"
                  color="primary"
                  startIcon={<SaveIcon />}
                  sx={{
                    mr: (theme) => theme.spacing(2),
                    position: 'relative',
                  }}
                >
                  Save
                </LoadingButton>
                <Button
                  size="small"
                  startIcon={<ClearIcon />}
                  sx={{
                    position: 'relative',
                  }}
                  onClick={() => history.push(`/admin/users`)}
                >
                  Cancel
                </Button>
              </Grid>
            </Form>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}

export default EditUser
