import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from '@reduxjs/toolkit'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'

import { LinearProgress } from '@mui/material';
import { DataGrid, GridToolbar, GridColDef } from '@mui/x-data-grid'
import Alert from '@mui/material/Alert'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { green, red } from '@mui/material/colors'

import { getUsers, deleteUser } from "../../../reducers/admin/usersSlice";

const UsersList = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const loading = false

  const onRowView = ({ row }) => {
    history.push(`/admin/users/${row.id}`)
  }

  const onRowUpdate = ({ row }) => {
    history.push(`/admin/users/${row.id}/edit`)
  }

  const onRowDelete = async ({ row }) => {
    Swal.fire({
      title: `Are you sure you want to delete this user?`,
      html: `Please write <span style="color: #d33;">${row.username}</span> to confirm.`,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed && result.value === row.username) {
        deleteUserAction(row)
      } else if (
        result.value !== row.username &&
        result.dismiss !== Swal.DismissReason.cancel
      ) {
        Swal.fire(
          `${row.username} cannot be deleted.`,
          'The username does not match.',
          'error'
        )

        return null
      }
    })
  }

  const deleteUserAction = async (user) => {
    if (addRequestStatus === 'idle') {
      try {
        setAddRequestStatus('pending')
        const resultAction = await dispatch(deleteUser(user))
        const deletedUser = unwrapResult(resultAction)
        Swal.fire({
          icon: 'success',
          title: `User: ${deletedUser.username} has been removed.`,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          dispatch(getUsers())
        })
      } catch (err) {
        Swal.fire('Error', err, 'error')
      } finally {
        setAddRequestStatus('idle')
      }
    }
  }

  const columns = useMemo(() => [
    {
      field: 'id',
      headerName: 'ID',
      width: 120,
      hide: true,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'First name',
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    { field: 'last_name', headerName: 'Last name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 120,
      hide: true,
    },
    { field: 'username', headerName: 'Username', width: 100 },
    {
      field: 'role',
      headerName: 'Role',
      width: 130,
      valueFormatter: (params) => (params.value ? params.value.name : ''),
    },
    {
      field: 'created_date',
      headerName: 'Created',
      type: 'date',
      width: 120,
      valueFormatter: (params) =>
          moment(params.value).locale('en').format('MMM DD, YYYY'),
    },
    {
      field: 'active',
      headerName: 'Status',
      type: 'boolean',
      width: 100,
      renderCell: ( row ) => (
          <Chip
              size="small"
              label={row.row.active ? 'Active' : 'Disabled'}
              style={{
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: row.row.active ? green[500] : red[500],
              }}
          />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      type: 'actions',
      width: 135,
      renderHeader: () => <strong>{'Actions'}</strong>,
      renderCell: (row) => (
          <div>
            <Tooltip title="View">
              <IconButton aria-label="view" onClick={() => onRowView(row)}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton aria-label="edit" onClick={() => onRowUpdate(row)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton aria-label="delete" onClick={() => onRowDelete(row)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
      ),
    },
  ], []);



  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const { error, usersArray } = useSelector((state) => state.admin.users)

  useEffect(() => {
    if (addRequestStatus === "idle") {
      try {
        setAddRequestStatus("pending");
        dispatch(getUsers());
      } catch (err) {
        // console.log(err)
      } finally {
        setAddRequestStatus("idle");  
      }
    }
  }, [addRequestStatus, dispatch]);

  return (
    <>
      {!!error && (
        <Alert sx={{ mb: 2 }} severity="error">
          {error}
        </Alert>
      )}
      <div style={{ height: '100%', width: '100%' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              autoHeight
              disableColumnMenu
              rows={usersArray}
              columns={columns}
              pageSizeOptions={[ 10, 25, 50, 100 ]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              loading={loading}
              slots={{
                toolbar: GridToolbar,
                loadingOverlay: LinearProgress,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 }
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default UsersList
