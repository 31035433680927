const baseUrl = process.env.REACT_APP_API_URL

export async function api(endpoint, { body, ...customConfig } = {}) {
    const url = `${baseUrl}/${endpoint}`
    const token = localStorage.getItem('token')
    const headers =
        body instanceof FormData ? {} : { 'Content-Type': 'application/json' }

    if (token) {
        headers['x-token'] = token
    }

    const config = {
        method: body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    }

    if (body) {
        config.body = body instanceof FormData ? body : JSON.stringify(body)
    }

    let data
    try {
        const response = await window.fetch(url, config)
        data = await response.json()
        if (response.ok) {
            return data
        }
        throw new Error(data.error ? data.error : response.statusText)
    } catch (err) {
        return Promise.reject(err.message ? err.message : data)
    }
}

api.get = function (url, customConfig = {}) {
    return api(url, { ...customConfig, method: 'GET' })
}

api.post = function (url, body, customConfig = {}) {
    return api(url, { ...customConfig, body })
}

api.put = function (url, body, customConfig = {}) {
    return api(url, { ...customConfig, body, method: 'PUT' })
}

api.delete = function (url, body, customConfig = {}) {
    return api(url, { ...customConfig, body, method: 'DELETE' })
}
