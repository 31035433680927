import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { api } from '../../services/api'

const initialState = {
  photoCodes: [],
  activePhotoCode: null,
  status: 'idle',
  error: null,
}

export const fetchPhotoCodes = createAsyncThunk(
  'admin/photoCodes/fetchPhotoCodes',
  async () => {
    const response = await api.get('photo-codes')
    return response.photoCodes
  }
)

export const fetchPhotoCodeById = createAsyncThunk(
  'admin/photoCodes/fetchPhotoCodeById',
  async (photoCodeId) => {
    const response = await api.get(`photo-codes/${photoCodeId}`)
    return response.photoCode
  }
)

export const addNewPhotoCode = createAsyncThunk(
  'admin/photoCodes/addNewPhotoCode',
  async (initialPhotoCode, { rejectWithValue }) => {
    try {
      const response = await api.post('photo-codes', initialPhotoCode)
      return response.photoCode
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const photoCodeUpdated = createAsyncThunk(
  'admin/photoCodes/photoCodeUpdated',
  async (photoCode, { rejectWithValue, getState }) => {
    const { id } = getState().admin.photoCodes.activePhotoCode
    photoCode.id = id

    try {
      const response = await api.put(`photo-codes/${photoCode.id}`, photoCode)
      return response.newPhotoCode
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const photoCodeDeleted = createAsyncThunk(
  'admin/photoCodes/photoCodeDeleted',
  async (photoCode, { rejectWithValue }) => {

    try {
      const response = await api.delete(
        `photo-codes/${photoCode.id}`,
        photoCode
      )
      return response.deletedPhotoCode
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const photoCodesSlice = createSlice({
  name: 'photoCodes',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPhotoCodes.pending, (state) => {
      state.status = 'loading'
      state.error = null
    })
    builder.addCase(fetchPhotoCodes.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.photoCodes = action.payload
    })
    builder.addCase(fetchPhotoCodes.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
    builder.addCase(fetchPhotoCodeById.pending, (state) => {
      state.status = 'loading'
      state.error = null
      state.activePhotoCode = null
    })
    builder.addCase(fetchPhotoCodeById.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.activePhotoCode = action.payload
    })
    builder.addCase(fetchPhotoCodeById.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
    builder.addCase(addNewPhotoCode.pending, (state) => {
      state.status = 'loading'
      state.error = null
    })
    builder.addCase(addNewPhotoCode.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.photoCodes.push(action.payload)
    })
    builder.addCase(addNewPhotoCode.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
    builder.addCase(photoCodeUpdated.pending, (state) => {
      state.status = 'loading'
      state.error = null
    })
    builder.addCase(photoCodeUpdated.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.activePhotoCode = null
    })
    builder.addCase(photoCodeUpdated.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
    builder.addCase(photoCodeDeleted.pending, (state) => {
      state.status = 'loading'
      state.error = null
    })
    builder.addCase(photoCodeDeleted.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.activePhotoCode = null
    })
    builder.addCase(photoCodeDeleted.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  },
})

export default photoCodesSlice.reducer

export const selectAllPhotoCodes = (state) => state.admin.photoCodes.photoCodes
