import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

const AuthRoute = ({ isLoggedIn, isAdmin, component: Component, ...rest }) => {
  const redirectTo = isAdmin ? '/admin' : '/'
  return (
    <Route
      {...rest}
      component={(props) =>
        !isLoggedIn ? <Component {...props} /> : <Redirect to={redirectTo} />
      }
    />
  )
}

AuthRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
}

export default AuthRoute
