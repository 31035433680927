import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { api } from '../../services/api'

export const checkCredentials = createAsyncThunk(
  'admin/auth/check',
  async () => {
    const response = await api.get('auth/renew')
    return response
  }
)

export const login = createAsyncThunk(
  'admin/auth/login',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await api.post('auth', {
        userField: credentials.username,
        password: credentials.password,
      })
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const forgotPass = createAsyncThunk(
  'admin/auth/forgotPassword',
  async (email, { rejectWithValue }) => {
    try {
      const response = await api.post('auth/forgot-password', email)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const resetPassword = createAsyncThunk(
  'admin/auth/resetPassword',
  async (newCredentials, { rejectWithValue }) => {
    try {
      const { resetId, newPassword } = newCredentials
      const response = await api.put(`auth/${resetId}`, {
        password: newPassword,
      })
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const initialState = {
  checking: true,
  forgotPassword: false,
  uuid: null,
  role: [],
  name: null,
  status: 'idle',
  error: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state, action) {
      localStorage.clear()
      state.checking = false
      state.role = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkCredentials.pending, (state) => {
      state.status = 'loading'
      state.error = null
    })
    builder.addCase(checkCredentials.fulfilled, (state, action) => {
      state.status = 'succeeded'

      const response = action.payload
      if (response.ok) {
        localStorage.setItem('token', response.token)
        localStorage.setItem('token-init-date', new Date().getTime())

        state.uuid = response.uid
        state.role = response.role
        state.name = response.name
        state.checking = false
      }
    })
    builder.addCase(checkCredentials.rejected, (state, action) => {
      state.status = 'failed'
      state.checking = false
      state.error = action.payload
    })
    builder.addCase(login.pending, (state) => {
      state.status = 'loading'
      state.error = null
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.status = 'succeeded'

      const response = action.payload
      if (response.ok) {
        localStorage.setItem('token', response.token)
        localStorage.setItem('token-init-date', new Date().getTime())

        state.uuid = response.uid
        state.role = response.role
        state.name = response.name
        state.checking = false
      }
    })
    builder.addCase(login.rejected, (state, action) => {
      state.status = 'failed'
      state.checking = false
      state.error = 'The username or password does not match.'
    })
    builder.addCase(forgotPass.pending, (state) => {
      state.status = 'loading'
      state.error = null
    })
    builder.addCase(forgotPass.fulfilled, (state, action) => {
      state.status = 'succeeded'

      const response = action.payload
      if (response.ok) {
        localStorage.setItem('reset-token', response.resetToken)
        localStorage.setItem('reset-token-init-date', new Date().getTime())

        state.checking = false
        state.forgotPassword = true
      }
    })
    builder.addCase(forgotPass.rejected, (state, action) => {
      state.status = 'failed'
      state.checking = false
      state.forgotPassword = false
      state.error = 'The email does not match.'
    })
    builder.addCase(resetPassword.pending, (state) => {
      state.status = 'loading'
      state.error = null
    })
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.status = 'succeeded'

      const response = action.payload
      if (response.ok) {
        state.checking = false
        state.forgotPassword = false
      }
    })
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.status = 'failed'
      state.checking = false
      state.forgotPassword = true
      state.error = 'Invalid password.'
    })
  },
})

export const { logout } = authSlice.actions
export default authSlice.reducer
