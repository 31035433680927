import React from 'react'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'

import RouterBreadcrumbs from '../layout/Breadcrumbs'
import PhotoCodesList from './PhotoCodesList'
import AddPhotoCode from './AddPhotoCode'
import PhotoCodeView from './PhotoCodeView'
// import PhotoCodeEdit from './PhotoCodeEdit'

const PhotoCodesScreen = () => {
  const history = useHistory()
  let match = useRouteMatch()

  const handleNewPhotoCode = () => {
    history.push(`/admin/photo-codes/new`)
  }

  return (
    <>
      <Grid item xs={12}>
        <RouterBreadcrumbs />
        <Switch>
          <Route path={`${match.path}/new`}>
            <AddPhotoCode />
          </Route>
          <Route path={`${match.path}/:photoCodeId`}>
            <PhotoCodeView />
          </Route>
          <Route path={match.path}>
            <Paper
              sx={{
                p: (theme) => theme.spacing(2),
                display: 'flex',
                overflow: 'auto',
                flexDirection: 'column',
              }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  Photo Codes
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{
                    mt: (theme) => theme.spacing(2),
                    mr: (theme) => theme.spacing(1),
                    mb: (theme) => theme.spacing(3),
                  }}
                  startIcon={<AddIcon />}
                  onClick={() => handleNewPhotoCode()}
                >
                  New
                </Button>
              </Grid>
              <PhotoCodesList />
            </Paper>
          </Route>
        </Switch>
      </Grid>
    </>
  )
}

export default PhotoCodesScreen
