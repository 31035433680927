import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'

import { useForm, Controller } from 'react-hook-form'
import { Form } from '../../shared/Form'
import { Input } from '../../shared/Input'
import { yupResolver } from '@hookform/resolvers/yup'
import photoCodeSchema from '../../../validators/photoCodeSchema'

import { photoCodeUpdated } from '../../../reducers/admin/photoCodesSlice'

const AddProduct = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(photoCodeSchema),
  })

  const photoCodeStatus = useSelector((state) => state.admin.photoCodes.status)
  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const { activePhotoCode } = useSelector((state) => state.admin.photoCodes)

  useEffect(() => {
    if (activePhotoCode) {
      reset({
        code: activePhotoCode.code,
        link: activePhotoCode.link,
        active: activePhotoCode.active,
      })
    }
  }, [activePhotoCode, reset])

  const onSubmit = async (data, e) => {
    e.preventDefault()

    if (addRequestStatus === 'idle') {
      try {
        setAddRequestStatus('pending')
        const resultAction = await dispatch(photoCodeUpdated(data))
        const photoCode = unwrapResult(resultAction)
        Swal.fire({
          icon: 'success',
          title: `Photo code: ${photoCode.code} has been updated.`,
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          history.push(`/admin/photo-codes`)
        })
      } catch (err) {
        Swal.fire('Error', err, 'error')
      } finally {
        setAddRequestStatus('idle')
      }
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: (theme) => theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              mb: (theme) => theme.spacing(2),
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
              }}
            >
              Edit Photo Code
            </Typography>
            <Typography variant="body2">
              Date: {moment().locale('en').format('MMM DD, YYYY')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            lg={6}
            sx={{
              mt: (theme) => theme.spacing(2),
            }}
          >
            <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Grid item xs={12}>
                <Controller
                  name="code"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      required
                      label="Code"
                      error={!!errors.code}
                      helperText={errors.code && errors.code.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="link"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      required
                      label="Link"
                      multiline
                      rows={3}
                      error={!!errors.link}
                      helperText={errors.link && errors.link.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="active"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Switch
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  )}
                />
                <label>Active</label>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  my: (theme) => theme.spacing(2),
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <LoadingButton
                  type="submit"
                  loading={photoCodeStatus === 'loading'}
                  loadingPosition="start"
                  variant="contained"
                  size="small"
                  color="primary"
                  startIcon={<SaveIcon />}
                  sx={{
                    mr: (theme) => theme.spacing(2),
                    position: 'relative',
                  }}
                >
                  Save
                </LoadingButton>
                <Button
                  size="small"
                  startIcon={<ClearIcon />}
                  sx={{
                    position: 'relative',
                  }}
                  onClick={() => history.push(`/admin/photo-codes`)}
                >
                  Cancel
                </Button>
              </Grid>
            </Form>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}

export default AddProduct
