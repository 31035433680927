import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useHistory,
} from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { green, red } from '@mui/material/colors'

import {
  getUserById,
  deleteUser,
} from '../../../reducers/admin/usersSlice'

import UserEdit from './UserEdit'

const UserView = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  let { userId } = useParams()
  let match = useRouteMatch()

  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const { activeUser } = useSelector((state) => state.admin.users)

  useEffect(() => {
    dispatch(getUserById(userId))
  }, [userId, dispatch, history])

  const handleEdit = () => {
    history.push(`/admin/users/${userId}/edit`)
  }

  const handleDelete = () => {
    Swal.fire({
      title: `Are you sure you want to delete this user?`,
      html: `Please write <span style="color: #d33;">${activeUser.username}</span> to confirm.`,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed && result.value === activeUser.username) {
        deleteUserAction(activeUser)
      } else if (
        result.value !== activeUser.username &&
        result.dismiss !== Swal.DismissReason.cancel
      ) {
        Swal.fire(
          `${activeUser.username} cannot be deleted.`,
          'The username does not match.',
          'error'
        )

        return null
      }
    })
  }

  const deleteUserAction = async (user) => {
    if (addRequestStatus === 'idle') {
      try {
        setAddRequestStatus('pending')
        const resultAction = await dispatch(deleteUser(user))
        const deletedUser = unwrapResult(resultAction)
        Swal.fire({
          icon: 'success',
          title: `User: ${deletedUser.username} has been removed.`,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          history.push(`/admin/users`)
        })
      } catch (err) {
        Swal.fire('Error', err, 'error')
      } finally {
        setAddRequestStatus('idle')
      }
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Switch>
          <Route path={`${match.path}/edit`}>
            <UserEdit />
          </Route>
          <Route path={match.path}>
            <Paper
              sx={{
                p: (theme) => theme.spacing(2),
                display: 'flex',
                overflow: 'auto',
                flexDirection: 'column',
              }}
            >
              <Grid item xs={12} md={7} lg={6}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    mb: (theme) => theme.spacing(2),
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    View User
                  </Typography>

                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => handleEdit()}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDelete()}
                  >
                    Delete
                  </Button>
                </Stack>
              </Grid>
              {activeUser && (
                <Grid item xs={12} md={7} lg={6}>
                  <Grid
                    container
                    sx={{
                      mb: (theme) => theme.spacing(2),
                      display: 'flex',
                      overflow: 'auto',
                      flexDirection: 'column',
                      justify: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
                      <Typography variant="h6">
                        {activeUser.username}
                      </Typography>
                      <Chip
                        size="small"
                        label={activeUser.active ? 'Active' : 'Disabled'}
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          backgroundColor: activeUser.active
                            ? green[500]
                            : red[500],
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      Full name
                    </Typography>
                    <Typography
                      variant="subtitle2"
                    >
                      {activeUser.name} {activeUser.last_name }
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      Email
                    </Typography>
                    <Typography
                      variant="subtitle2"
                    >
                      {activeUser.email }
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      Phone
                    </Typography>
                    <Typography
                      variant="subtitle2"
                    >
                      {activeUser.phone }
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      Created
                    </Typography>
                    <Typography variant="body2">
                      {moment(activeUser.created_date)
                        .locale('en')
                        .format('MMM DD, YYYY')}
                    </Typography>
                  </Stack>
                </Grid>
              )}
            </Paper>
          </Route>
        </Switch>
      </Grid>
    </>
  )
}

export default UserView
