import * as yup from 'yup'

const resetPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Field is required.')
    .min(8, 'Password must be at least 8 chars long.'),
})

export default resetPasswordSchema
