import React from "react";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

import RouterBreadcrumbs from "../layout/Breadcrumbs";
import UsersList from "./UsersList";
import AddUser from "./AddUser";
import UserView from './UserView'

const UsersScreen = () => {
  const history = useHistory();
  let match = useRouteMatch();

  const handleNewUser = () => {
    history.push(`/admin/users/new`);
  };

  return (
    <>
      <Grid item xs={12}>
        <RouterBreadcrumbs />
        <Switch>
          <Route path={`${match.path}/new`}>
            <AddUser />
          </Route>
          <Route path={`${match.path}/:userId`}>
            <UserView />
          </Route>
          <Route path={match.path}>
            <Paper
              sx={{
                p: (theme) => theme.spacing(2),
                display: "flex",
                overflow: "auto",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Users
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{
                    mt: (theme) => theme.spacing(2),
                    mr: (theme) => theme.spacing(1),
                    mb: (theme) => theme.spacing(3),
                  }}
                  startIcon={<AddIcon />}
                  onClick={() => handleNewUser()}
                >
                  Add User
                </Button>
              </Grid>
              <UsersList />
            </Paper>
          </Route>
        </Switch>
      </Grid>
    </>
  );
};

export default UsersScreen;
