import React from 'react'
import { Route } from 'react-router-dom'

import Admin from '../components/admin/Admin'

const AdminRouter = () => {
  return <Route path={'/admin'} component={Admin} />
}

export default AdminRouter
