import * as yup from 'yup'

const editUserSchema = yup.object().shape({
  name: yup.string().required('Field is required'),
  last_name: yup.string().required('Field is required'),
  email: yup
    .string()
    .required('Field is required')
    .email('Please enter a valid email address'),
  username: yup.string().required('Field is required'),
  phone: yup.string().required('Field is required'),
})

export default editUserSchema
