import { combineReducers } from 'redux'

import authReducer from './authSlice'
import usersReducer from './usersSlice';
import photoCodesReducer from './photoCodesSlice'

export const adminReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  photoCodes: photoCodesReducer,
})
