import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { api } from '../services/api'

export const fetchPhotosByCode = createAsyncThunk(
  'photos/fetchPhotosByCode',
  async (downloadCode) => {
    const response = await api.get(`photo-codes?downloadCode=${downloadCode.code}`)
    return response.photoCode
  }
)

const initialState = {
  photoObject: null,
  photoCode: null,
  status: 'idle',
  error: null,
}

const photoSlice = createSlice({
  name: 'photos',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPhotosByCode.pending, (state) => {
      state.status = 'loading'
      state.error = null
      state.photoObject = null
    })
    builder.addCase(fetchPhotosByCode.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.photoObject = action.payload
    })
    builder.addCase(fetchPhotosByCode.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  },
})

export default photoSlice.reducer
