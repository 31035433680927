import React from 'react'
import { Link as RouterLink, Route } from 'react-router-dom'
import Link from '@mui/material/Link'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'

const breadcrumbNameMap = {
  '/admin': 'Dashboard',
  '/admin/users': 'Users',
  '/admin/photo-codes': 'Photo Codes',
  //   '/admin/photo-codes/:productId': 'View Product',
}

const toTitleCase = (str) => {
  return str.replace(/\b\w+/g, function (s) {
    return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()
  })
}

export default function RouterBreadcrumbs() {
  return (
    <Route>
      {({ location }) => {
        const pathnames = location.pathname.split('/').filter((x) => x)
        return (
          <Breadcrumbs
            aria-label="Breadcrumb"
            sx={{
              pb: (theme) => theme.spacing(2),
            }}
          >
            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1
              const to = `/${pathnames.slice(0, index + 1).join('/')}`

              return last ? (
                <Typography
                  color="textPrimary"
                  key={to}
                  sx={{
                    color: (theme) => theme.palette.text.primary,
                  }}
                >
                  {breadcrumbNameMap[to]
                    ? breadcrumbNameMap[to]
                    : toTitleCase(value)}
                </Typography>
              ) : (
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to={to}
                  key={to}
                >
                  {breadcrumbNameMap[to]
                    ? breadcrumbNameMap[to]
                    : toTitleCase(value)}
                  {/* {toTitleCase(value)} */}
                </Link>
              )
            })}
          </Breadcrumbs>
        )
      }}
    </Route>
  )
}
