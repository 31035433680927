import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api";

export const getRoles = createAsyncThunk(
  "admin/roles/fetch",
  async () => {
    const response = await api.get("roles")
    return response
  }
);
  
export const getUsers = createAsyncThunk(
  "admin/users/fetch",
  async () => {
    const response = await api.get("users")
    return response
  }
);

export const getUserById = createAsyncThunk(
  'admin/users/fetchUserById',
  async (userId) => {
    const response = await api.get(`users/${userId}`)
    return response.user
  }
);
  
export const addUser = createAsyncThunk(
  "admin/users/new",
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.post("users", {
        name: params.name,
        last_name: params.last_name,
        email: params.email,
        username: params.username,
        password: params.password,
        phone: params.phone,
        role: params.role,
        active: params.active,
        created_date: params.created_date,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateUser = createAsyncThunk(
  'admin/users/update',
  async (user, { rejectWithValue, getState }) => {
    const { id } = getState().admin.users.activeUser
    user.id = id

    try {
      const response = await api.put(`users/${user.id}`, user)
      return response.user
    } catch (err) {
      return rejectWithValue(err)
    }
  }
);

export const deleteUser = createAsyncThunk(
  'admin/users/delete',
  async (user, { rejectWithValue }) => {

    try {
      const response = await api.delete(
        `users/${user.id}`,
        user
      )
      return response.user
    } catch (err) {
      return rejectWithValue(err)
    }
  }
);

const initialState = {
  usersArray: [],
  roles: [],
  activeUser: null,
  status: "idle",
  error: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getRoles.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.status = "succeeded";

      const response = action.payload
      if (response.ok) {
        state.roles = response.roles;
      }
    });
    builder.addCase(getRoles.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(getUsers.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.status = "succeeded";

      const response = action.payload
      if (response.ok) {
        state.usersArray = response.users;
      }
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(getUserById.pending, (state) => {
      state.status = 'loading'
      state.error = null
      state.activeUser = null
    });
    builder.addCase(getUserById.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.activeUser = action.payload
    });
    builder.addCase(getUserById.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    });
    builder.addCase(addUser.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(addUser.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(updateUser.pending, (state) => {
      state.status = 'loading'
      state.error = null
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.activeUser = null
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    });
    builder.addCase(deleteUser.pending, (state) => {
      state.status = 'loading'
      state.error = null
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.activeUser = null
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    });
  },
});

export default usersSlice.reducer;
