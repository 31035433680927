import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'

export const Form = ({ children, ...props }) => {
  return (
    <form noValidate {...props}>
      <Grid container spacing={3}>
        {children}
      </Grid>
    </form>
  )
}

Form.propTypes = {
  children: PropTypes.array.isRequired,
}
