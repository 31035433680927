import DashboardScreen from '../components/admin/dashboard/DashboardScreen'
import UsersScreen from '../components/admin/users/UsersScreen'
import PhotoCodesScreen from '../components/admin/photo-codes/PhotoCodesScreen'

export const adminRoutes = [
  {
    path: '/',
    exact: true,
    name: 'Dashboard',
    component: DashboardScreen,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardScreen,
  },
  {
    path: '/users',
    name: 'Users',
    component: UsersScreen,
  },
  {
    path: '/photo-codes',
    name: 'PhotoCodes',
    component: PhotoCodesScreen,
  },
]
