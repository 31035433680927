import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Alert from '@mui/material/Alert'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { styled } from '@mui/material/styles'

import { useForm, Controller } from 'react-hook-form'
import { Form } from '../shared/Form'
import { Input } from '../shared/Input'
import { yupResolver } from '@hookform/resolvers/yup'
import loginSchema from '../../validators/loginSchema'

import { login } from '../../reducers/admin/authSlice'

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://pezgato.com/">
        Pezgato
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const Img = styled('img')({
  margin: 40,
  display: 'block',
  maxWidth: 160,
  maxHeight: '100%',
})

const theme = createTheme()

const LoginScreen = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(loginSchema),
    defaultValues: {
      username: '',
      password: '',
      rememberMe: false,
    },
  })

  const { status, error } = useSelector((state) => state.admin.auth)
  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const [values, setValues] = React.useState({
    showPassword: false,
  })

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const onSubmit = async (data, e) => {
    e.preventDefault()

    if (addRequestStatus === 'idle') {
      try {
        setAddRequestStatus('pending')
        const resultAction = await dispatch(login(data))
        const loginResult = unwrapResult(resultAction)
        Swal.fire({
          icon: 'success',
          title: `Hi ${loginResult.name}, you have been logged in.`,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          history.push(`/admin`)
        })
      } catch (err) {
        console.log(err)
      } finally {
        setAddRequestStatus('idle')
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Img
            alt="logo"
            src={`${process.env.REACT_APP_BASE_IMG_URL}/pezgato-logo.png`}
          />
          <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
            Admin
          </Typography>
          <Box sx={{ mt: 1 }}>
            {!!error && (
              <Alert sx={{ mb: 2 }} severity="error">
                {error}
              </Alert>
            )}
            <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Grid item xs={12}>
                <Controller
                  name="username"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      required
                      autoFocus
                      label="Email or username"
                      error={!!errors.username}
                      helperText={errors.username && errors.username.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      required
                      label="Password"
                      id="password"
                      type={values.showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.password}
                      helperText={errors.password && errors.password.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <label>Remember me</label>
                <Controller
                  name="rememberMe"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox {...field} color="primary" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={status === 'loading'}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
              </Grid>
            </Form>
            <Grid container>
              <Grid item xs>
                <Link href="/login/forgotpassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  )
}

export default LoginScreen
