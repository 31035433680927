import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Alert from '@mui/material/Alert'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { styled } from '@mui/material/styles'

import { useForm, Controller } from 'react-hook-form'
import { Form } from '../shared/Form'
import { Input } from '../shared/Input'
import { yupResolver } from '@hookform/resolvers/yup'
import forgotPassSchema from '../../validators/forgotPassSchema'

import { forgotPass } from '../../reducers/admin/authSlice'

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://pezgato.com/">
        Pezgato
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const Img = styled('img')({
  margin: 40,
  display: 'block',
  maxWidth: 160,
  maxHeight: '100%',
})

const theme = createTheme()

const ForgotPasswordScreen = () => {
  const dispatch = useDispatch()

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(forgotPassSchema),
    defaultValues: {
      email: '',
    },
  })

  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const { forgotPassword, error } = useSelector((state) => state.admin.auth)

  const onSubmit = async (data, e) => {
    e.preventDefault()

    if (addRequestStatus === 'idle') {
      try {
        setAddRequestStatus('pending')
        const resultAction = await dispatch(forgotPass(data))
        const loginResult = unwrapResult(resultAction)
        console.log(loginResult)
      } catch (err) {
        // console.log(err)
      } finally {
        setAddRequestStatus('idle')
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Img
            alt="logo"
            src={`${process.env.REACT_APP_BASE_IMG_URL}/pezgato-logo.png`}
          />
          <Typography component="h1" variant="h5">
            Password Reset
          </Typography>
          <Typography
            variant="body2"
            color="text.primary"
            align="center"
            sx={{ mt: 2, mb: 2 }}
          >
            Please provide the email associated with your account. You will
            receive an email containing instructions to reset your password.
          </Typography>
          <Box sx={{ mt: 1 }}>
            {!!error && (
              <Alert sx={{ mb: 2 }} severity="error">
                {error}
              </Alert>
            )}
            {!!forgotPassword && (
              <>
                <Alert sx={{ mb: 2 }} severity="success">
                  If this email is associated with an account, instructions to
                  reset your password will be sent to you shortly.
                </Alert>
                <Typography
                  variant="caption"
                  color="text.primary"
                  align="center"
                >
                  In the event that you did not receive an email to reset your
                  password, please:
                </Typography>
                <ul>
                  <li>
                    <Typography variant="caption" color="text.primary">
                      Check back in a few minutes
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="caption" color="text.primary">
                      Check your junk folder
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="caption" color="text.primary">
                      Contact support
                    </Typography>
                  </li>
                </ul>
              </>
            )}
            <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      required
                      autoFocus
                      label="Email address"
                      error={!!errors.email}
                      helperText={errors.email && errors.email.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={forgotPassword}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Send Password Reset Email
                </Button>
              </Grid>
            </Form>
            <Grid container>
              <Grid item xs align="center">
                <Link href="/login" variant="body2">
                  Go back to Log In
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  )
}

export default ForgotPasswordScreen
