import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useHistory, useParams } from 'react-router-dom'

import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { styled } from '@mui/material/styles'

import { useForm, Controller } from 'react-hook-form'
import { Form } from '../shared/Form'
import { Input } from '../shared/Input'
import { yupResolver } from '@hookform/resolvers/yup'
import resetPasswordSchema from '../../validators/resetPasswordSchema'

import { resetPassword } from '../../reducers/admin/authSlice'

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://pezgato.com/">
        Pezgato
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const Img = styled('img')({
  margin: 40,
  display: 'block',
  maxWidth: 160,
  maxHeight: '100%',
})

const theme = createTheme()

const NewPasswordScreen = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { key } = useParams()

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: {
      newPassword: '',
      resetId: key,
    },
  })

  const { error } = useSelector((state) => state.admin.auth)
  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const [values, setValues] = React.useState({
    showPassword: false,
  })

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const onSubmit = async (data, e) => {
    e.preventDefault()

    if (addRequestStatus === 'idle') {
      try {
        setAddRequestStatus('pending')
        const resultAction = await dispatch(resetPassword(data))
        const loginResult = unwrapResult(resultAction)
        console.log(loginResult)
        history.push(`/admin`)
      } catch (err) {
        // console.log(err)
      } finally {
        setAddRequestStatus('idle')
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Img
            alt="logo"
            src={`${process.env.REACT_APP_BASE_IMG_URL}/pezgato-logo.png`}
          />
          <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
            Reset your Password
          </Typography>
          <Box sx={{ mt: 1 }}>
            {!!error && (
              <Alert sx={{ mb: 2 }} severity="error">
                {error}
              </Alert>
            )}
            <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Grid item xs={12}>
                <Controller
                  name="newPassword"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      required
                      label="New Password"
                      id="newPassword"
                      type={values.showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.newPassword}
                      helperText={
                        errors.newPassword && errors.newPassword.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Update and Login
                </Button>
              </Grid>
            </Form>
            <Grid container>
              <Grid item xs align="center">
                <Link href="/login" variant="body2">
                  Go back to Log In
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  )
}

export default NewPasswordScreen
