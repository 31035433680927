import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import moment from "moment";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

import { useForm, Controller } from "react-hook-form";
import { Form } from "../../shared/Form";
import { Input } from "../../shared/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import addUserSchema from "../../../validators/addUserSchema";

import { getRoles, addUser } from "../../../reducers/admin/usersSlice";

const PaperContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  overflow: "auto",
  flexDirection: "column",
}));

const theme = createTheme();

const initUser = {
  name: "",
  last_name: "",
  email: "",
  username: "",
  password: "",
  phone: "",
  role: null,
  active: false,
  created_date: new Date()
}

const AddUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(addUserSchema),
    defaultValues: initUser,
  });

  const { roles } = useSelector((state) => state.admin.users)
  const usersStatus = useSelector((state) => state.admin.users.status)
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  useEffect(() => {
    if (addRequestStatus === "idle") {
      try {
        setAddRequestStatus("pending");
        dispatch(getRoles());
      } catch (err) {
        // console.log(err)
      } finally {
        setAddRequestStatus("idle");  
      }
    }
  }, [addRequestStatus, dispatch]);

  const onSubmit = async (data, e) => {
    e.preventDefault();

    if (addRequestStatus === "idle") {
      try {
        setAddRequestStatus("pending");
        const resultAction = await dispatch(addUser(data));
        const addUserResult = unwrapResult(resultAction);
        Swal.fire({
          icon: 'success',
          title: `New user: ${addUserResult.newUser.username} has been added.`,
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          history.push(`/admin/users`)
        })
      } catch (err) {
        Swal.fire('Error', err, 'error')
      } finally {
        setAddRequestStatus("idle");
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid item xs={12}>
        <Paper>
          <PaperContainer>
            <Grid
              item
              xs={12}
              sx={{
                mb: (theme) => theme.spacing(2),
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Add User
              </Typography>
              <Typography variant="body2">
                Date: {moment().locale("en").format("MMM DD, YYYY")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              lg={6}
              sx={{ mt: (theme) => theme.spacing(2) }}
            >
              <Form
                // encType="multipart/form-data"
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid item xs={12} md={6}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        {...field}
                        required
                        label="First Name"
                        error={!!errors.name}
                        helperText={errors.name && errors.name.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="last_name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        {...field}
                        required
                        label="Last Name"
                        error={!!errors.last_name}
                        helperText={
                          errors.last_name && errors.last_name.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    type="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        {...field}
                        required
                        label="Email"
                        error={!!errors.email}
                        helperText={errors.email && errors.email.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="username"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        {...field}
                        required
                        label="Username"
                        error={!!errors.username}
                        helperText={errors.username && errors.username.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        {...field}
                        required
                        label="Password"
                        error={!!errors.password}
                        helperText={errors.password && errors.password.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="phone"
                    type="number"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        {...field}
                        required
                        label="Phone"
                        error={!!errors.phone}
                        helperText={errors.phone && errors.phone.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        id="select_role"
                        name="select_role"
                        options={roles}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Choose a role"
                            variant="outlined"
                            required
                            error={!!errors.role}
                            helperText={errors.role && errors.role.message}
                          />
                        )}
                        onChange={(_, data) => field.onChange(data)}
                      />
                    )}
                    name="role"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="active"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Switch
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    )}
                  />
                  <label>Active</label>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    my: (theme) => theme.spacing(2),
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <LoadingButton
                    type="submit"
                    loading={usersStatus === 'loading'}
                    loadingPosition="start"
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<SaveIcon />}
                    sx={{
                      mr: (theme) => theme.spacing(2),
                      position: 'relative',
                    }}
                  >
                    Save
                  </LoadingButton>

                  <Button
                    size="small"
                    startIcon={<ClearIcon />}
                    sx={{
                      position: 'relative',
                    }}
                    onClick={() => history.push(`/admin/users`)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Form>
            </Grid>
          </PaperContainer>
        </Paper>
      </Grid>
    </ThemeProvider>
  );
};

export default AddUser;
