import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

import PublicRoute from './PublicRoute'
import AuthRoute from './AuthRoute'
import AuthRouter from './AuthRouter'
import AdminRoute from './AdminRoute'
import AdminRouter from './AdminRouter'

import HomeScreen from '../components/home/HomeScreen'
import { checkCredentials } from '../reducers/admin/authSlice'

const AppRouter = () => {
  const dispatch = useDispatch()
  const { checking, uuid, role } = useSelector((state) => state.admin.auth)
  const isAdmin = role.includes('admin') || role.includes('superAdmin')

  useEffect(() => {
    dispatch(checkCredentials())
  }, [dispatch])

  if (checking) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={checking}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return (
    <Router>
      <Switch>
        {/* Rutas Públicas */}
        <PublicRoute exact path="/" component={HomeScreen} />

        {/* Rutas Privadas */}
        <AuthRoute
          path="/login"
          component={AuthRouter}
          isLoggedIn={!!uuid}
          isAdmin={!!isAdmin}
        />
        <AdminRoute
          path="/admin"
          component={AdminRouter}
          isLoggedIn={!!uuid}
          isAdmin={!!isAdmin}
        />

        <Redirect to="/" />
      </Switch>
    </Router>
  )
}

export default AppRouter
