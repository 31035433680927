import * as yup from 'yup'

const forgotPassSchema = yup.object().shape({
  email: yup
    .string()
    .required('Field is required')
    .email('Please enter a valid email address'),
})

export default forgotPassSchema
