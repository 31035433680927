import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import moment from 'moment'

const DashboardScreen = () => {
  const { name } = useSelector((state) => state.admin.auth)

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Welcome {name}!</Typography>
        <Typography variant="body2">
          {moment().locale('en').format('dddd, MMMM Do, YYYY')}
        </Typography>
      </Grid>
    </>
  )
}

export default DashboardScreen
