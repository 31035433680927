import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useHistory,
} from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Link from '@mui/material/Link'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { green, red } from '@mui/material/colors'

import {
  fetchPhotoCodeById,
  photoCodeDeleted,
} from '../../../reducers/admin/photoCodesSlice'

import PhotoCodeEdit from './PhotoCodeEdit'

const PhotoCodeView = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  let { photoCodeId } = useParams()
  let match = useRouteMatch()

  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const { activePhotoCode } = useSelector((state) => state.admin.photoCodes)

  useEffect(() => {
    dispatch(fetchPhotoCodeById(photoCodeId))
  }, [photoCodeId, dispatch, history])

  const handleEdit = () => {
    history.push(`/admin/photo-codes/${photoCodeId}/edit`)
  }

  const handleDelete = () => {
    Swal.fire({
      title: `Are you sure you want to delete this photo code?`,
      html: `Please write <span style="color: #d33;">${activePhotoCode.code}</span> to confirm.`,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed && result.value === activePhotoCode.code) {
        deletePhotoCode(activePhotoCode)
      } else if (
        result.value !== activePhotoCode.code &&
        result.dismiss !== Swal.DismissReason.cancel
      ) {
        Swal.fire(
          `${activePhotoCode.code} cannot be deleted.`,
          'The code does not match.',
          'error'
        )

        return null
      }
    })
  }

  const deletePhotoCode = async (photoCode) => {
    if (addRequestStatus === 'idle') {
      try {
        setAddRequestStatus('pending')
        const resultAction = await dispatch(photoCodeDeleted(photoCode))
        const deletedPhotoCode = unwrapResult(resultAction)
        Swal.fire({
          icon: 'success',
          title: `Photo code: ${deletedPhotoCode.code} has been removed.`,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          history.push(`/admin/photo-codes`)
        })
      } catch (err) {
        Swal.fire('Error', err, 'error')
      } finally {
        setAddRequestStatus('idle')
      }
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Switch>
          <Route path={`${match.path}/edit`}>
            <PhotoCodeEdit />
          </Route>
          <Route path={match.path}>
            <Paper
              sx={{
                p: (theme) => theme.spacing(2),
                display: 'flex',
                overflow: 'auto',
                flexDirection: 'column',
              }}
            >
              <Grid item xs={12} md={7} lg={6}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    mb: (theme) => theme.spacing(2),
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    View Photo Code
                  </Typography>

                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => handleEdit()}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDelete()}
                  >
                    Delete
                  </Button>
                </Stack>
              </Grid>
              {activePhotoCode && (
                <Grid item xs={12} md={7} lg={6}>
                  <Grid
                    container
                    sx={{
                      mb: (theme) => theme.spacing(2),
                      display: 'flex',
                      overflow: 'auto',
                      flexDirection: 'column',
                      justify: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
                      <Typography variant="h6">
                        {activePhotoCode.code}
                      </Typography>
                      <Chip
                        size="small"
                        label={activePhotoCode.active ? 'Active' : 'Disabled'}
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          backgroundColor: activePhotoCode.active
                            ? green[500]
                            : red[500],
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      pb: (theme) => theme.spacing(1),
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      Created
                    </Typography>
                    <Typography variant="body2">
                      {moment(activePhotoCode.created)
                        .locale('en')
                        .format('MMM DD, YYYY')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      pb: (theme) => theme.spacing(1),
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      Link
                    </Typography>
                    <Link
                      href={activePhotoCode.link}
                      variant="body2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {activePhotoCode.link}
                    </Link>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Route>
        </Switch>
      </Grid>
    </>
  )
}

export default PhotoCodeView
