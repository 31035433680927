import * as React from 'react'
import { Switch, Route } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Dashboard from './layout/Dashboard'
import Footer from './layout/Footer'

import { adminRoutes } from '../../routers/AdminRoutes';

const mdTheme = createTheme()

const Admin = () => {
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Dashboard />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Switch>
              <Route
                path="/admin"
                render={({ match: { path } }) => (
                  <>
                    {adminRoutes.map((route, i) => (
                      <Route
                        key={i}
                        path={`${path}${route.path}`}
                        exact={route.exact}
                        component={route.component}
                      />
                    ))}
                  </>
                )}
              />
            </Switch>
            <Footer sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default Admin
