import React, { forwardRef } from 'react'
import TextField from '@mui/material/TextField'

export const Input = forwardRef((props, ref) => (
  <TextField
    variant="outlined"
    fullWidth
    InputLabelProps={{
      shrink: true,
    }}
    inputRef={ref}
    {...props}
  />
))

Input.displayName = 'Input'
