import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <>
      <Route {...rest} component={(props) => <Component {...props} />} />
    </>
  )
}

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
}

export default PublicRoute
