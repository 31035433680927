import React from 'react'
import { Switch, Route } from 'react-router-dom'

import LoginScreen from '../components/auth/LoginScreen'
import ForgotPasswordScreen from '../components/auth/ForgotPasswordScreen'
import NewPasswordScreen from '../components/auth/NewPasswordScreen'

const AuthRouter = () => {
  return (
    <Switch>
      <Route exact path="/login" component={LoginScreen} />
      <Route
        exact
        path="/login/forgotpassword"
        component={ForgotPasswordScreen}
      />
      <Route exact path="/login/:key" component={NewPasswordScreen} />
    </Switch>
  )
}

export default AuthRouter
