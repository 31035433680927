import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'

import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Chip from '@mui/material/Chip'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { green, red } from '@mui/material/colors'

import {
  selectAllPhotoCodes,
  fetchPhotoCodes,
  photoCodeDeleted,
} from '../../../reducers/admin/photoCodesSlice'
import {LinearProgress} from "@mui/material";

const PhotoCodesList = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const photoCodes = useSelector(selectAllPhotoCodes)
  const photoCodeStatus = useSelector((state) => state.admin.photoCodes.status)
  const error = useSelector((state) => state.admin.photoCodes.error)
  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  useEffect(() => {
    dispatch(fetchPhotoCodes())
  }, [dispatch]);

  const onRowView = ({ row }) => {
    history.push(`/admin/photo-codes/${row.id}`)
  }

  const onRowUpdate = ({ row }) => {
    history.push(`/admin/photo-codes/${row.id}/edit`)
  }

  const onRowDelete = async ({ row }) => {
    Swal.fire({
      title: `Are you sure you want to delete this photo code?`,
      html: `Please write <span style="color: #d33;">${row.code}</span> to confirm.`,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed && result.value === row.code) {
        deletePhotoCode(row)
      } else if (
        result.value !== row.code &&
        result.dismiss !== Swal.DismissReason.cancel
      ) {
        Swal.fire(
          `${row.code} cannot be deleted.`,
          'The code does not match.',
          'error'
        )

        return null
      }
    })
  }

  const deletePhotoCode = async (photoCode) => {
    if (addRequestStatus === 'idle') {
      try {
        setAddRequestStatus('pending')
        const resultAction = await dispatch(photoCodeDeleted(photoCode))
        const deletedPhotoCode = unwrapResult(resultAction)
        Swal.fire({
          icon: 'success',
          title: `Photo code: ${deletedPhotoCode.code} has been removed.`,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          dispatch(fetchPhotoCodes())
        })
      } catch (err) {
        Swal.fire('Error', err, 'error')
      } finally {
        setAddRequestStatus('idle')
      }
    }
  }

  const columns = useMemo(() => [
    {
      field: 'id',
      headerName: 'ID',
      width: 120,
      hide: true,
      flex: 1,
      renderHeader: () => <strong>{'ID'}</strong>,
    },
    {
      field: 'code',
      headerName: 'Code',
      width: 120,
      renderHeader: () => <strong>{'Code'}</strong>,
    },
    {
      field: 'link',
      headerName: 'Link',
      flex: 1,
      renderHeader: () => <strong>{'Link'}</strong>,
      renderCell: (row) => (
          <Link href={row.value} variant="body2" target="_blank" rel="noreferrer">
            {row.value}
          </Link>
      ),
    },
    {
      field: 'createDate',
      headerName: 'Created',
      type: 'date',
      width: 120,
      renderHeader: () => <strong>{'Created'}</strong>,
      valueFormatter: (params) =>
          moment(params.value).locale('en').format('MMM DD, YYYY'),
    },
    /* {
      field: 'expireDate',
      headerName: 'Expire',
      type: 'date',
      width: 120,
      renderHeader: () => <strong>{'Expire'}</strong>,
      valueFormatter: (params) =>
        moment(params.value).locale('en').format('MMM DD, YYYY'),
    }, */
    {
      field: 'active',
      headerName: 'Status',
      type: 'boolean',
      width: 100,
      renderHeader: () => <strong>{'Status'}</strong>,
      renderCell: (row) => (
          <Chip
              size="small"
              label={row.row.active ? 'Active' : 'Disabled'}
              style={{
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: row.row.active ? green[500] : red[500],
              }}
          />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      type: 'actions',
      width: 160,
      renderHeader: () => <strong>{'Actions'}</strong>,
      renderCell: (row) => (
          <div>
            <Tooltip title="View">
              <IconButton aria-label="view" onClick={() => onRowView(row)}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton aria-label="edit" onClick={() => onRowUpdate(row)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton aria-label="delete" onClick={() => onRowDelete(row)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
      ),
    },
  ], []);

  return (
    <>
      <div style={{ height: '100%', width: '100%' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              disableColumnMenu
              rows={photoCodes}
              columns={columns}
              pageSizeOptions={[ 10, 25, 50, 100 ]}
              loading={photoCodeStatus === 'loading'}
              error={error}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              slots={{
                toolbar: GridToolbar,
                loadingOverlay: LinearProgress,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 }
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default PhotoCodesList
